@import "../../styles/base.scss";

$aspectRatio: 6/16;
$imageAspectRatio: 5/9;

$mobileAspectRatio: 21/18;
$mobileImageAspectRatio: 9/16;

$previewFadeInTime: 1000ms;
$previewFadeInDelayTime: 1300ms;
$previewFadeOutTime: 900ms;

.KeyArt {
  min-height: calc(100vw * #{$aspectRatio});
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.expanded {
    height: min-content;
  }

  .image, .preview {
    background-size: cover;
  }

  .preview {
    pointer-events: none;
    width: 100%;
    height: calc(100vw * #{$aspectRatio});

    >div, video-js {
      width: 100%;
      height: 100%;
    }

    .video-js {
      display: block;
      opacity: 0;

      video {
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        transition: opacity 1s;
      }

      >div, button {
        display: none !important;
      }
      &.vjs-playing.vjs-has-started {
        opacity: 1;
        transition: opacity $previewFadeInTime ease $previewFadeInDelayTime;
      }
      &.vjs-ended {
        opacity: 0;
        transition: opacity $previewFadeOutTime;
      }
    }
  }

  .overlay {
    .mute-btn {
      display: none;
      position: relative;
      width: 24px;
      height: 24px;
      float: right;
      cursor: pointer;
      background: url(../../assets/images/icons/volume-btn.svg);
    }
    .mute-btn.muted {
      background: url(../../assets/images/icons/volume-btn-muted.svg);
    }
  }

  .metadata {
    overflow: hidden;
    height: 370px;
    background-image: linear-gradient(to bottom, transparent, $background);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 40px 75px;

    &-wrapper {
      max-width: 483px;
    }

    .header {
      font-family: $font-flama;
      text-transform: uppercase;
      font-size: 52px;
      font-weight: bold;
      display: block;
      color: $white;
      margin-bottom: 5px;
    }

    .border {
      width: 100%
    }

    .subheader {
      font-family: $font-flama-semi;
      font-size: 17px;
      display: block;
      color: $white;
      font-weight: bold;
      margin-bottom: 4px;
      line-height: 24px;
    }

    .info {
      font-family: $font-flama-semi;
      font-size: 12px;
      display: block;
      color: $white;
      margin-bottom: 6px;
    }

    .description {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 17px;
      display: block;
      color: $white;
      line-height: 24px;
    }

    .Expander {
      margin-bottom: 32px;
    }

    .social-buttons {
      display: flex;
      margin-top: 15px;
      >div {
        margin-right: 4px;
      }
    }
  }

  .overlay {
    width: 24px;
    height: 24px;
    top: 40px;
    right: 40px;
    z-index: 100;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: $tablet-wide) {
  .KeyArt {
    .metadata {
      .description {
        font-size: 14px;
      }
    }

    &.expanded {
      .metadata .description {
        max-height: none !important;
      }
    }
  }
}


@media screen and (max-width: $tablet) {
  .KeyArt {
    .metadata {

      .header {
        font-size: 33px;
      }

      .description {
        font-size: 14px;
        max-height: 50px;
      }
    }

    &.expanded {
      .metadata .description {
        max-height: none !important;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .KeyArt {
    grid-template-columns: auto;
    grid-template-rows: calc(#{$mobileImageAspectRatio}*100vw) auto;
    height: calc(100vw * #{$mobileAspectRatio});

    .header {
      max-height: 40px;
    }

    .image, .preview {
      grid-column: 1;
      grid-row: 1;
    }

    .metadata {
      grid-column: 1;
      grid-row: 2;
      border-width: $mobile-margin;
    }

    &.expanded {
      .metadata .description {
        max-height: none !important;
      }
    }
  }
}

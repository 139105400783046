@import "../../styles/base.scss";

$aspectRatio: 25/72;

$playerWidthRatio: 760/1440;
$playerWidthRatioTabletWide: 600/1024;
$playerAspectRatio: 9/16;

.PlayerDetail {
  display: grid;
  padding: 42px $desktop-large-margin 35px $desktop-large-margin;
  grid-gap: 30px;
  grid-template-columns: calc(#{$playerWidthRatio}*100vw) auto;
  min-height: calc(100vw * #{$aspectRatio});

  .social-buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    >div {
      margin-right: 4px;
    }
  }

  .playerWrapper {
    position:relative;
    grid-column: 1;
    grid-row: 1;
    background-color: $background;
    background-size: "contain";
    background-repeat: "no-repeat";
    background-position: "center";
    height: calc(#{$playerWidthRatio*$playerAspectRatio}*100vw);
  }
  .player {
    background-color: $background;
    background-size: "contain";
    background-repeat: "no-repeat";
    background-position: "center";
    height: calc(#{$playerWidthRatio*$playerAspectRatio}*100vw);

    .video-js {
      width: 100%;
      height: 100%;

      .vjs-control-bar {
        height: 64px;
        background-color: rgba($background, 0.85);
      }

      .vjs-play-progress, .vjs-volume-level {
        background-color: $primary-red;
      }

      .vjs-button > .vjs-icon-placeholder:before {
        line-height: 3.67 !important;
      }

      .vjs-big-play-button {
        background: url(../../assets/images/icons/play-icon-xxlg.svg) !important;

        .vjs-icon-placeholder:before {
          content: "";
        }
      }

      .vjs-dock-shelf, .vjs-dock-text {
        transition: none;
      }

      &.vjs-has-started:not(:hover) .vjs-dock-shelf, .vjs-has-started:not(:hover) .vjs-dock-text {
        opacity: 1;
        transition: none;
      }

      &.vjs-playing .vjs-dock-shelf, &.vjs-playing .vjs-dock-text {
        opacity: 0;
        transition: opacity .25s 3s;
      }

      &.vjs-paused .vjs-dock-shelf, &.vjs-paused .vjs-dock-text {
        opacity: 1;
      }

      .vjs-play-control {
        .vjs-icon-placeholder:before, .vjs-icon-play:before {
          content: "";
          background: url(../../assets/images/icons/play.svg) no-repeat 11px 20px;
        }

        &.vjs-ended .vjs-icon-placeholder:before, .vjs-icon-replay:before {
          content: "";
        }

        &.vjs-playing .vjs-icon-placeholder:before, .vjs-icon-pause:before {
          content: "";
          background: url(../../assets/images/icons/pause.svg) no-repeat 11px 20px;
        }
      }

      .vjs-mute-control {
        .vjs-icon-placeholder:before, .vjs-icon-volume-high:before {
          content: "";
          background: url(../../assets/images/icons/volume-btn.svg) no-repeat 11px 20px;
        }

        &.vjs-vol-2 .vjs-icon-placeholder:before, .vjs-icon-volume-mid:before {
          content: "";
          background: url(../../assets/images/icons/volume-btn-med.svg) no-repeat 11px 20px;
        }

        &.vjs-vol-1 .vjs-icon-placeholder:before, .vjs-icon-volume-low:before {
          content: "";
          background: url(../../assets/images/icons/volume-btn-low.svg) no-repeat 11px 20px;
        }

        &.vjs-vol-0 .vjs-icon-placeholder:before, .vjs-icon-volume-mute:before {
          content: "";
          background: url(../../assets/images/icons/volume-btn-muted.svg) no-repeat 11px 20px;
        }
      }

      .vjs-volume-control {
        &.vjs-volume-vertical {
          width: 24px !important;
          height: 122px !important;
          bottom: 118px !important;
          left: -37px !important;
          background-color: #1e1e1e;
        }

        .vjs-volume-triangle {
          position: absolute;
          bottom: -11.4px;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 11px solid #1e1e1e;
        }

        .vjs-volume-handle {
            width: 32px;
            height: 32px;
            left: -14px;
            top: -18px;
            position: absolute;
            display: block;
            background: url(../../assets/images/icons/handle.svg) no-repeat;
        }
      }

      .vjs-volume-bar {
        &.vjs-slider-vertical {
          width: 4px;
          height: 101px;
          border-radius: 1px;
          background-color: rgba(255, 255, 255, 0.3);
        }
      }


      .vjs-cc-btn {
        cursor: pointer;
        background: url(../../assets/images/icons/cc.svg) no-repeat 11px 20px;

        &.vjs-cc-on {
          background: url(../../assets/images/icons/cc-on.svg) no-repeat 11px 20px;
        }
      }

      .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-enter:before {
        content: "";
        background: url(../../assets/images/icons/maximize.svg) no-repeat 11px 20px;
      }

      .vjs-play-progress .vjs-progress-handle {
          width: 32px;
          height: 32px;
          position: absolute;
          top: -0.9rem;
          display: block;
          background: url(../../assets/images/icons/handle.svg) no-repeat;
      }

      .vjs-current-time-display, .vjs-duration-display {
        font-family: $font-flama-semi;
        font-size: 12px;
        color: #f1f1f1;
        line-height: 5.5 !important;
      }

      .vjs-progress-control {
        position: relative;
        left: auto;
        right: auto;
        width: 368px;
      }

      &.not-hover.vjs-has-started .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
        transform: translateY(6em) !important;
      }

      .vjs-progress-control {
        height: 4px !important;
        top: 2.5em !important;
      }

      &.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-exit:before {
        content: "";
        background: url(../../assets/images/icons/minimize.svg) no-repeat 11px 20px;
      }

      .vjs-loading-spinner {
        border: none;
      }

      &.vjs-seeking .vjs-loading-spinner:after, &.vjs-seeking .vjs-loading-spinner:before, &.vjs-waiting .vjs-loading-spinner:after, &.vjs-waiting .vjs-loading-spinner:before {
        background: url(../../assets/images/icons/spinner.svg) no-repeat;
        background-size: cover;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }

      &.vjs-seeking .vjs-loading-spinner:after, &.vjs-waiting .vjs-loading-spinner:after {
        border-top-color: transparent;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
    }

    .next-overlay {
      visibility: hidden;
      cursor: pointer;
      display: inline-flex;
      position: absolute;
      width: 430px;
      height: 90px;
      right: 0;
      bottom: 64px;
      background-color: rgba($background, 0.85);

      .next-overlay-thumbnail {
        display: inline-block;
        width: 160px;
        height: 100%;
      }

      .next-overlay-logo {
        display: inline-block;
        height: 100%;
        width: 28px;
        position: relative;
        background: url(../../assets/images/icons/logo-mark-sm.svg) no-repeat 7px 8px;
      }

      .next-overlay-infopanel {
        display: inline-block;
        position: relative;
        width: 189px;
        height: 100%;

        .next-overlay-upnext {
          font-family: $font-flama-semi;
          font-size: 17px;
          font-weight: bold;
          color: #f1f1f1;
          margin-top: 7px;
        }

        .next-overlay-countdown {
          font-family: $font-flama-semi;
          font-size: 14px;
          color: #f1f1f1;
          margin-top: 7px;
        }

        .next-overlay-seriestitle {
          font-family: $font-flama-semi;
          font-size: 14px;
          font-weight: bold;
          width: 100%;
          color: $primary-red;
          margin-top: 7px;
        }

        .next-overlay-episodetitle {
          font-family: $font-flama-semi;
          font-size: 14px;
          font-weight: normal;
          width: 100%;
          color: #f1f1f1;
          margin-top: 4px;
        }
      }
    }
  }

  .metadata {
    grid-column: 2;
    grid-row: 1;
    position: relative;

    .header {
      font-size: 21px;
      color: $primary-red;
      font-family: $font-flama-semi;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .subheader {
      font-size: 52px;
      font-family: $font-flama-semi;
      font-weight: bold;
      margin-bottom: 20px;
      color: $white;
    }

    .info {
      font-size: 12px;
      font-family: $font-flama-semi;
      color: $white;
      margin-bottom: 6px;
    }

    .description {
      font-size: 17px;
      line-height: 24px;
      color: $white;
      font-family: Helvetica, Arial, sans-serif;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .PlayerDetail {
    padding: $tablet-margin;
    grid-gap: 16px;
    grid-template-columns: calc(#{$playerWidthRatioTabletWide}*100vw) auto;

    .player {
      height: calc(#{$playerWidthRatioTabletWide*$playerAspectRatio}*100vw);
    }

    .metadata {
      .subheader {
        font-size: 33px;
      }

      .description {
        max-height: 160px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .PlayerDetail {
    padding: 0;
    grid-gap: 0;
    grid-template-columns: auto;
    grid-template-rows: calc(#{$playerAspectRatio}*100vw) auto;

    .player {
      height: calc(#{$playerAspectRatio}*100vw);
      grid-column: 1;
      grid-row: 1;
    }

    .metadata {
      grid-column: 1;
      grid-row: 2;
      padding: 24px $tablet-margin 48px $tablet-margin;
    }

    .social-buttons {
      bottom: 24px;
    }
  }
}

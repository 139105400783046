@import "../../styles/base.scss";

.Carousel {
  &-header {
    font-family: $font-flama-semi;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 10px;
    margin-left: 1.2rem;
  }

  .slick-slider {
    .slick-track {
      margin-left: 0;
    }
    .slick-arrow {
      img:nth-child(2), img:nth-child(3){
        display: none;
      }
      &:hover:not(.slick-disabled) {
        img:first-child, img:nth-child(3) {
          display: none;
        }
        img:nth-child(2){
          display: inline;
        }
      }
      &.slick-disabled {
        img:first-child, img:nth-child(2) {
          display: none;
        }
        img:nth-child(3){
          display: inline;
        }
      }
    }

    .slick-prev {
      left: -35px;
    }

    .slick-next {
      right: -35px;
    }

    .slick-next, .slick-prev {
      width: auto;
      height: auto;
      top: 35%;

      &:before, &:after {
        content: none;
      }
    }

    .Tile {
      display: flex;
      flex-direction: column;
      margin: 1rem 1.4rem 24px 1.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .scroll {
    display: flex;
    overflow-y: scroll;

    div:not(last-child) {
      margin-right: 10px;
    }
  }
}

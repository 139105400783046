@import "../../styles/base.scss";

.Header {
  display: grid;
  grid-template-rows: auto minmax(min-content, max-content) minmax(min-content, max-content);
  grid-template-columns: 100%;
  color: $black;
  font-family: $font-flama-semi;
  font-weight: bold;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 38px;
  height: 50px;
  background-image: linear-gradient(to top, transparent, $background);

  >div {
    padding: 0 $tablet-margin;
  }

  .arrow {
    margin-right: 10px;
  }
  .menu {
    display: none;
  }
}

.nav {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: auto auto 1fr auto;
  font-size: 21px;
  font-weight: 500;

  >div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &-logo {
    img {
      width: 160px;
      height: auto;
    }
  }

  &-search.mobile {
    display: none;
  }

  &-divider {
    display: none;
    visibility: none;
  }

  &-menu_primary {
    >div {
      margin-left: 3rem;

      a, a:visited {
        text-decoration: none;
        color: $white;
        font-size: 16px;
        font-weight: 500;

        &.active {
          padding-bottom: 5px;
          border-bottom: 2px solid $primary-red;
        }
      }

      &.mobile {
        display: none;
        &_link {
          cursor:pointer;
        }
      }
    }
  }

  &-menu_secondary {
    justify-content: flex-end;
    >div {
      cursor: pointer;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      &.nav-search img{
        width: 19px;
        height: 19px;
        margin-top: 5px;
      }
    }

    .Header-user {
      display: flex;
      align-items: flex-start;
      font-size: 21px;

      >div {
        margin-left: $tablet-margin;
        &>span {
          font-size: 16px;
        }
      }

      img {
        height: 27px;
        margin-left: 20px;
      }
    }
  }
}



@media screen and (max-width: $tablet) {
  [class^="nav-menu"] {
    font-size: 17px;
  }
}

@media screen and (max-width: $small) {
  .Header {
    height: auto;
    top: 28px;
    .menu {
      display: inherit;
      padding: 0 2rem;

      img {
        cursor: pointer;
      }
    }

    .nav {
      height: 100%;
      grid-template-columns: 50px 1fr auto;
      grid-template-rows: 56px minmax(min-content, max-content) 100%;
      padding: 0px;
      grid-column: 1/4;
      grid-row: 2;

      &-logo {
        img {
          width:120px;
        }
      }

      &-search.mobile {
        display: flex;
        padding-right: 2rem;
        img {
          width: 25px;
          height: 25px;
        }
      }

      &-divider {
        height: 4px;
        background: $black;
        display: inherit;
        grid-column: 1/4;
      }

      &-menu_primary {
        flex-direction: column;
        grid-column: 1/3;
        z-index: 5;
        background: $white;
        align-items: flex-start;
        font-size: 21px;

        >div {
          margin:20px 0 0 8rem;

          &:last-child {
            margin-bottom: 28px;
          }

          &.mobile {
            display: block;
          }
          a {
            color: $black;
          }
        }

        &.menu_closed {
          display: none;
        }
      }

      &-menu_secondary {
        display: none;
      }
    }
  }
}

@import "../../styles/base.scss";

.HomePage {
  margin-top: 38px !important;
  .Hero {
    margin-bottom: 36px;
  }

  .Carousel, .Grid {
    margin: 36px $desktop-large-margin;
  }
}

@media screen and (max-width: $tablet) {
  .HomePage {
    .Hero {
      margin-bottom: 12px;
    }

    .Carousel, .Grid {
      margin: 12px $tablet-margin;
    }
  }
}

@media screen and (max-width: $mobile) {
  .HomePage {
    .Carousel, .Grid {
      margin: $mobile-margin;
    }
  }
}

@media screen and (max-width: $small) {
  .HomePage {
    margin-top: 89px !important;
  }
}

@import "../../styles/base.scss";

.InfoPage {
  padding: 46px $desktop-large-margin $desktop-large-margin $desktop-large-margin !important;
  display: grid;
  grid-template-columns: 290px auto;
  grid-template-rows: min-content;

  &-menu {
    grid-column: 1;
    grid-row: 2;

    li {
      text-transform: uppercase;
      font-family: $font-flama-semi;
      font-weight: bold;
      color: $white;
      margin-bottom: 30px;
      font-size: 14px;
      cursor: pointer;

      &.selected {
        color: $primary-red;
      }
    }
  }

  &-header {
    grid-column: 2;
    grid-row: 1;
    color: $white;
    font-family: $font-flama;
    font-size: 52px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 48px;
  }

  &-body {
    grid-column: 2;
    grid-row: 2;
    color: $white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 24px;

    p:first-child {
      margin-top: 0;
    }

    a {
      &, &:visited, &:active, &:focus {
        color: $primary-red;
      }
    }
  }
}


@media screen and (max-width: $tablet-wide) {
  .InfoPage {
    padding: 44px $tablet-margin $tablet-margin $tablet-margin !important;
    grid-template-columns: 192px auto;

    &-header {
      font-size: 33px;
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .InfoPage {
    padding: 20px $mobile-margin !important;
    grid-template-columns: auto;

    &-menu {
      grid-column: 1;
      grid-row: 1;
      margin-bottom: $mobile-margin;
      overflow: hidden;

      ul {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
      }

      li {
        display: inline-block;
        margin: 0 30px 0 0;
        padding-bottom: 5px;

        &.selected {
          border-bottom: 2px solid $primary-red;
        }
      }
    }

    &-header {
      grid-column: 1;
      grid-row: 2;
      font-size: 33px;
      margin-bottom: 40px;
    }

    &-body {
      grid-column: 1;
      grid-row: 3;
      font-size: 14px;
    }
  }
}

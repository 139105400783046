@import "../../styles/base.scss";

.Expander {
  color: $primary-red;
  font-family: $font-flama-semi;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
}

@import "../../styles/base.scss";

$aspectRatio: 6/16;
$imageAspectRatio: 5/9;
$mobileAspectRatio: 19/18;
$smallMobileAspectRatio: 430/360;
$mobileImageAspectRatio: 9/16;
$mobileMetadataAspectRatio: 23/20;

.Slide {
  width: 100%;
  min-height: calc(100vw * #{$aspectRatio});
  position: relative;
  .image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: calc(100vw * #{$aspectRatio});
    height: auto;
  }

  .play-icon {
    position: absolute;
    right: $desktop-large-margin;
    bottom: $desktop-large-margin;
    width: 60px;
    z-index: 20;

    img {
      @include equal-dimensions(100%);
    }
  }

  .metadata {
    overflow: hidden;
    min-height: 318px;
    background-image: linear-gradient(to bottom, transparent, $background);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 40px 75px;

    &-wrapper {
      max-width: 483px;
    }

    .header {
      font-family: $font-flama;
      text-transform: uppercase;
      font-size: 52px;
      font-weight: bold;
      display: block;
      color: $white;
      margin-bottom: 5px;
    }

    .border {
      width: 100%
    }

    .subheader {
      font-family: $font-flama-semi;
      font-size: 17px;
      display: block;
      color: $white;
      font-weight: bold;
      margin-bottom: 4px;
      line-height: 24px;
    }

    .info {
      font-family: $font-flama-semi;
      font-size: 12px;
      display: block;
      color: $white;
      margin-bottom: 6px;
    }

    .description {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 17px;
      display: block;
      color: $white;
      line-height: 24px;
      max-height: 125px;
    }

    .title-underline {
      width: 100%;
      margin: -25px 0 0 -10px;
    }
  }

  .overlay {
    grid-column: 2;
    grid-row: 1;
  }
}

@media screen and (max-width: $tablet-wide) {
  .Slide {
    .play-icon {
      right: $tablet-margin;
      bottom: $tablet-margin;
      width: 40px;
    }

    .metadata {
      border-width: 17px $tablet-margin $tablet-margin $tablet-margin;

      .description {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .Slide {
    grid-template-columns: auto;
    grid-template-rows: calc(#{$mobileImageAspectRatio}*100vw) calc(#{$mobileMetadataAspectRatio}*100vw);
    height: calc(100vw * #{$mobileAspectRatio});

    .header {
      max-height: 40px;
    }

    .image {
      grid-column: 1;
      grid-row: 1;
      position: relative;
    }

    .play-icon {
      right: $mobile-margin;
      bottom: $mobile-margin;
    }

    .metadata {
      grid-column: 1;
      grid-row: 2;
      border-width: $mobile-margin;
      padding: 40px 45px;

      .header {
        font-size: 26px;
      }

      .title-underline {
        max-width: 340px;
        margin: -17px 0 0 -7px;
      }

      .description {
        font-size: 14px;
        max-height: 50px;
      }
    }
  }
}

@media screen and (max-width: $mobile-small) {
  .LiveSlide {
    height: calc(100vw * #{$smallMobileAspectRatio});
  }
}

@font-face {
    font-family: 'Flama';
    src: url(~assets/fonts/FlamaCondensed-Light.woff2) format('woff2'),
        url(~assets/fonts/FlamaCondensed-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url(~assets/fonts/FlamaCondensed-Basic.woff2) format('woff2'),
        url(~assets/fonts/FlamaCondensed-Basic.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url(~assets/fonts/FlamaCondensed-Medium.woff2) format('woff2'),
        url(~assets/fonts/FlamaCondensed-Medium.woff) format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Flama';
    src: url(~assets/fonts/FlamaCondensed-Bold.woff2) format('woff2'),
        url(~assets/fonts/FlamaCondensed-Bold.woff) format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Flama-SC';
    src: url(~assets/fonts/FlamaSemicondensed-Light.woff2) format('woff2'),
        url(~assets/fonts/FlamaSemicondensed-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Flama-SC';
    src: url(~assets/fonts/FlamaSemicondensed-Basic.woff2) format('woff2'),
        url(~assets/fonts/FlamaSemicondensed-Basic.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Flama-SC';
    src: url(~assets/fonts/FlamaSemicondensed-Medium.woff2) format('woff2'),
        url(~assets/fonts/FlamaSemicondensed-Medium.woff) format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Flama-SC';
    src: url(~assets/fonts/FlamaSemicondensed-Bold.woff2) format('woff2'),
        url(~assets/fonts/FlamaSemicondensed-Bold.woff) format('woff');
    font-weight: 600;
}


// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../assets/fonts/FlamaCondensed-Black.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../assets/fonts/FlamaCondensed-Light.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Black.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../assets/fonts/FlamaCondensed-Bold.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed Book';
//     src: url('../assets/fonts/FlamaCondensed-Book.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Book.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Medium.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Extrabold.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Extrabold.woff') format('woff');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Ultralight.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Ultralight.woff') format('woff');
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed';
//     src: url('../assets/fonts/FlamaCondensed-Semibold.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Semibold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Semibold.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Semibold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'DIN Next LT Pro';
//     src: url('../assets/fonts/DINNextLTPro-Bold.woff2') format('woff2'),
//         url('../assets/fonts/DINNextLTPro-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed Book';
//     src: url('../assets/fonts/FlamaSemicondensed-Book.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Book.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed Basic';
//     src: url('../assets/fonts/FlamaSemicondensed-Basic.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Basic.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Semicondensed';
//     src: url('../assets/fonts/FlamaSemicondensed-Bold.woff2') format('woff2'),
//         url('../assets/fonts/FlamaSemicondensed-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'DIN Next LT Pro';
//     src: url('../assets/fonts/DINNextLTPro-Medium.woff2') format('woff2'),
//         url('../assets/fonts/DINNextLTPro-Medium.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Flama Condensed Basic';
//     src: url('../assets/fonts/FlamaCondensed-Basic.woff2') format('woff2'),
//         url('../assets/fonts/FlamaCondensed-Basic.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'DIN Next LT Pro';
//     src: url('../assets/fonts/DINNextLTPro-Regular.woff2') format('woff2'),
//         url('../assets/fonts/DINNextLTPro-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

@import "../../styles/base.scss";

.LiveStreamHeader {
  width: 100%;
  font-family: 'Flama-SC', Helvetica, Arial, sans-serif;
  color: white;
  position: absolute;
  top: 0;
  left: 0;

  &-back {
    margin: 18px 35px 0 16px;
    float: left;
    line-height: 24px;
    cursor: pointer;
  }

  &-info {
    float: left;
    margin: 16px 0 0 0;
    text-align: left;

    .show-name {
      font-size: 17px;
      font-weight: bold;
      line-height: 24px;
    }

    .episode-name {
      font-size: 14px;
      line-height: 20px;
    }

    .episode-timeslot {
      font-size: 12px;
      font-family: Helvetica, Arial, sans-serif;
      line-height: 18px;
      color: white;
    }
  }

  &-schedule {
    float: right;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin: 16px 32px 0 0;
    line-height: 24px;
    cursor: pointer;
  }

  &-close-schedule {
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
    width: 24px;
  }

  /* HEADER */
  .LiveStreamHeader {
    width: 100%;
    font-family: "Flama-SC", Helvetica, Arial, sans-serif;
    color: #f1f1f1;
    position: relative;
    top: 0;
    left: 0;
  }
  .LiveStreamHeader-back {
    margin: 18px 35px 0 16px;
    float: left;
    line-height: 24px;
    cursor: pointer;
  }
  .LiveStreamHeader-info {
    float: left;
    margin: 16px 0 0 0;
    text-align: left;
  }
  .LiveStreamHeader-info .show-name {
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
  }
  .LiveStreamHeader-info .episode-name {
    font-size: 14px;
    line-height: 20px;
  }
  .LiveStreamHeader-info .episode-timeslot {
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 18px;
    color: white;
  }
  .LiveStreamHeader-schedule {
    float: right;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin: 16px 32px 0 0;
    line-height: 24px;
    cursor: pointer;
  }
  .LiveStreamHeader-close-schedule {
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1002;
    width: 24px;
  }

  @media screen and (max-width: 600px) {
    .LiveStreamHeader-back {
      margin: 14px 10px 0 12px;
    }
    .LiveStreamHeader-info {
      margin: 12px 0 0 0;
      max-width: 50%;
    }
    .LiveStreamHeader-schedule {
      margin: 12px 32px 0 0;
    }
    .LiveStreamHeader-close-schedule {
      top: 14px;
      right: 30px;
    }
  }

  /* EXPANDER */

  .Expander {
    color: $primary-red;
    font-family: 'Flama-SC', Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin-top: 5px;
  }
  .Expander > svg {
    display: inline-block;
    font-size: inherit;
    height: 1.06667em;
    width: 1.06667em;
    overflow: visible;
    vertical-align: -.125em;
    opacity: .82;
  }
}

/* SCHEDULE */
.Schedule.embedded {
  position: absolute;
  top: 0;
  right: 0px;
  width: 480px;
  overflow-y: hidden;
  margin-top: 0;
  height: 100%;
  width: 480px;
  float: right;
  z-index: 1001;
  margin: 0;
  height: calc(100% + 30px);

  .Schedule-upper-section {
    border-bottom: 2px solid $primary-red;
    z-index: 50;
    background-color: $background;
    top: 0;
    position: relative;
  }
  .Schedule-upper-section.fixed {
    position: sticky;
  }
  .Schedule-title-bar {
    align-items: flex-end;
    justify-content: space-between;
    padding: 22px 16px 0 90px;
    display: block;
    text-align: left;
    margin-bottom: 30px;
  }
  .Schedule-title-bar > div {
    display: block;
  }
  .Schedule-header {
    display: inline-block;
    font-family: "Flama", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;
  }
  .Schedule-timezone {
    display: inline-block;
    text-align: left;
    margin-top: 16px;
  }
  .Schedule-timezone .span {
    text-transform: uppercase;
  }
  .Schedule-timezone .select {

    background-color: rgba(0, 0, 0, 0);
    padding: 2px 4px 2px 2px;
  }
  .Schedule-timezone .select select {
    font-family: "Flama-SC", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: $primary-red;
    border-radius: 2px;
  }
  .Schedule-timezone .select::after {
    color: $primary-red !important;
    font-size: 1rem;
  }
  .Schedule-timezone span:first-child {
    font-family: "Flama-SC", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  .Schedule-timeline {
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 0;
    margin: 0 36px;
    justify-content: space-between;
  }
  .Schedule-timeline-day {
    display: inline-block;
    padding: 9px;
    text-align: center;
    width: 51px;
    cursor: pointer;
  }
  .Schedule-timeline-day span:first-child {
    display: block;
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }
  .Schedule-timeline-day span:last-child {
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
    font-size: 12px;
  }
  .Schedule-timeline-day.same-day {
    border-bottom: 3px solid $primary-red;
  }
  .Schedule-timeline-day.same-day span:first-child {
    color: $primary-red;
  }
  .Schedule-timeline-arrow {
    position: absolute;
    bottom: 19px;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
  .Schedule-timeline-arrow.disabled {
    color: $background-28;
    cursor: default;
    pointer-events: none;
  }
  .Schedule-timeline-arrow.left {
    left: 15px;
  }
  .Schedule-timeline-arrow.right {
    right: 15px;
  }
  .Schedule-entries {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 213px;
  }

  .ScheduleEntry {
    background-color: $background;
    display: flex;
    border-top: 1px solid $dark-yellow;
    border-bottom: 1px solid $dark-yellow;
    padding: 20px 16px 20px 0;
  }
  .ScheduleEntry.now-playing {
    background-color: $dark-gray;
  }
  .ScheduleEntry > div {
    display: inline-block;
    text-align: left;
  }
  .ScheduleEntry-time {
    min-width: 90px;
    width: 90px;
    max-width: 90px;
    padding-left: 16px;
  }
  .ScheduleEntry-time > div {
    display: inline-block;
  }
  .ScheduleEntry-time .now-playing-icon {
    display: inline-block;
    padding-top: 5px;
    width: 14px;
    margin-right: 4px;
  }
  .ScheduleEntry-time .now-mobile {
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
    font-weight: bold;
    font-size: 17px;
    color: $white;
    display: inline-block;
  }
  .ScheduleEntry-time .now, .ScheduleEntry-time .now-playing-placeholder {
    display: none;
  }
  .ScheduleEntry-time .time {
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
    font-size: 17px;
    color: $white;
  }
  .ScheduleEntry-show, .ScheduleEntry-episode, .ScheduleEntry-info, .ScheduleEntry-description {
    color: $white;
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
  }
  .ScheduleEntry-show {
    font-size: 17px;
    font-weight: bold;
  }
  .ScheduleEntry-episode {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 14px;
  }
  .ScheduleEntry-info {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .ScheduleEntry-watch-now {
    font-size: 14px;
    font-weight: bold;
    color: $primary-red;
    text-transform: uppercase;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .ScheduleEntry-watch-now a, .ScheduleEntry-watch-now a:visited, .ScheduleEntry-watch-now a:active {
    color: $primary-red;
    text-decoration: none;
  }
  .ScheduleEntry-watch-now img {
    margin-right: 3px;
  }
  .ScheduleEntry-description {
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    max-height: 51px;
  }
  .expanded .ScheduleEntry-description {
    max-height: none;
  }
  .ScheduleEntry-toggle {
    color: $primary-red;
    font-family: "Flama", Helvetica, Arial, sans-serif-semi;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .Schedule.embedded {
    position: absolute;
    width: inherit !important;
  }
}

.force-show {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}
.force-hide {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

@import "../../styles/base.scss";
@import "../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../node_modules/slick-carousel/slick/slick-theme.css";

$aspectRatio: 16 / 5;
$mobileAspectRatio: 16 / 9;

.Hero {
  .slick-slider {

    .slick-arrow {
      img:nth-child(2) {
        display: none;
      }

      &:hover:not(.slick-disabled) {
        img:first-child {
          display: none;
        }

        img:nth-child(2) {
          display: inline;
        }
      }
    }

    .slick-next {
      right: 25px;
      width: auto;
      height: auto;

      &:before {
        content: none;
      }
    }

    .slick-prev {
      left: 25px;
      width: auto;
      height: auto;
      z-index: 1;

      &:before {
        content: none;
      }
    }

    .slick-dots {
      bottom: 25px;
      li {
        button {
          border-radius: 20px;
          background: $yellow-gradient;

          &:before {
            top: 1px;
            left: .15px;
            color: $black;
            font-size: 18px;
            opacity: 1;
          }
        }
        &.slick-active {
          box-shadow: none;
          border: none;
          button {
            background: $yellow-gradient;

            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
  .slick-slider { max-width: calc(100vh * #{$aspectRatio}); }

  @media screen and (max-width: $tablet-wide) {
    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    .slick-slider {
      .slick-dots {
        bottom: 0;
        position: relative;
      }
    }
  }
}

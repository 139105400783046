@import "../../../styles/base.scss";

.LiveMarquee {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;
    background-image: linear-gradient(to bottom, transparent, $background);
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    padding: 40px 75px;
  }

  .MarqueeListing {
    margin: 0 16px 16px 0;
    max-width: 280px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    >div {
      display: flex;
    }

    .listing-body {
      line-height: 15px;

      &-header {
        font-weight: bold;
        color: $white;
        height: 100% !important;
      }

      &-subheader {
        font-size: 14px;
        line-height: 14px;
        color: $white;
        height: 100% !important;
      }
    }

    &-time {
      align-items: flex-start;
      border-bottom: 2px solid $primary-red;

      .listing-body {
        padding: 0 0 10px 10px;
      }

      img {
        max-width: 28px;
        width: 100%;
      }
    }

    &-media {
      margin: 6px 0;

      .listing-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 0 15px;
        font-size: 14px;

        &-label {
          background: $yellow-gradient;
          width: auto;
          color: $slide-label-text;
          margin-bottom: 6px;
          font-size: 12px;
          width: 70px;
          text-align: center;
          padding: 2px 2px 0;
          span {
            margin-right: 3px;
            &:first-child {
              font-weight: 500;
            }
          }
        }

        &-header {
          color: $primary-red;
          height: 18px;
          font-weight: bold;
        }
        &-subheader {
          color: $white;
          height: 18px;
        }
      }
      img {
        width: 100px;
        height: 56px;
        object-fit: cover;
      }
    }
  }

  &.has-live .LiveMarquee-wrapper{
    .MarqueeListing:first-child {
      width: 100%;
      max-width: 480px;
      .MarqueeListing-media {
        img {
          display: none;
        }
        .listing-body {
          padding: 0;
          &-label span {
            font-size: 16px;
            font-weight: 500;
            width: 70px;
            &:last-child {
              display: none;
            }
          }
          &-header {
            font-size: 52px;
            font-weight: bold;
            line-height: 1.08;
            color: $white;
            height: auto;
            text-transform: uppercase;
          }
          &-subheader {
            height: 30px;
            font-size: 30px;
            line-height: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .LiveMarquee {
    padding: $tablet-margin $tablet-margin 10px $tablet-margin;

    .MarqueeListing {
      margin: 10px 10px 0 0;

      &-time {
        img {
          max-width: 14px;
        }

        .listing-body {
          padding: 0 0 5px 5px;

          &-header {
            display: inline-block;
            width: 77px;
          }

          &-subheader {
            display: inline-block;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small) {
  .LiveMarquee .MarqueeListing:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: $tablet) {
  .LiveMarquee {
    flex-direction: column;
    width: 100%;
    padding: $mobile-margin;
    background-color: $background;

    .MarqueeListing {
      max-width: 100%;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      &-time {
        img {
          max-width: 14px;
        }

        .listing-body {
          padding: 0 0 5px 5px;

          >div {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-small) {

  .LiveMarquee.has-live
  .LiveMarquee-wrapper
  .MarqueeListing:first-child
  .MarqueeListing-media {
    .listing-body {
      &-header {
        font-size: 40px;
      }

      &-subheader {
        font-size: 25px;
      }
    }
  }
}


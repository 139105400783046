@import "../../styles/base.scss";

.georestriction {
  background-color: $background;

  .app-body {
    .GeoRestrictionPage {
      padding: 0;
      height: 1024px;
      background-color: transparent;

      .Background {
        position: relative;
        padding: 76px;
        height: 691px;
        width: 100%;
        background-position: top right;
        background-repeat: no-repeat;
        background-image: image-set("../../assets/images/activate/background-image.jpg" 1x, "../../assets/images/activate/background-image@2x.jpg" 2x,
        "../../assets/images/activate/background-image@3x.jpg" 3x);
        background-size: contain;

        .Logo {
          margin-bottom: 51px;

          img {
            width: 200px;
            height: 59.6px;
            object-fit: contain;
          }
        }

        .Title {
          width: 644px;
          margin-bottom: 48px;
          text-transform: uppercase;
          font-size: 52px;
          font-weight: bold;
        }

        .Subtitle {
          width: 644px;
          p {
            font-family: Helvetica;
            font-size: 17px;
          }
        }
      }

      .Gradient {
        position: absolute;
        bottom: 333px;
        height: 265px;
        width: 100%;
        background-image: $background;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .georestriction {
    .app-body {
      .GeoRestrictionPage {
        .Background {
          background-image: image-set("../../assets/images/georestriction/background-image.jpg" 1x, "../../assets/images/georestriction/background-image@2x.jpg" 2x,
          "../../assets/images/georestriction/background-image@3x.jpg" 3x);
          padding: 46px 24px;

          .Title {
            width: auto;
            margin-bottom: 40px;
            font-size: 33px;
          }

          .subtitles {
            width: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .georestriction {
    .app-body {
      .GeoRestrictionPage {
        .Background {
          background-image: none;
          padding: 24px 16px;
          .Logo {
            margin-bottom: 44px;
          }

          .Title {
            width: auto;
            margin-bottom: 20px;
            font-size: 33px;
          }

          .Subtitle {
            width: auto;
          }
        }

        .Gradient {
          display: none;
        }
      }
    }
  }
}

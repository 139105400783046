@import "../../styles/base.scss";

.VideoPage {
  .Carousel {
    margin: 0 $desktop-large-margin 36px $desktop-large-margin;
  }
}

@media screen and (max-width: $tablet-wide) {
  .VideoPage {
    .Carousel {
      margin: 0 $tablet-margin 12px $tablet-margin;
    }
  }
}

@media screen and (max-width: $mobile) {
  .VideoPage {
    .Carousel {
      margin: 0 $mobile-margin $mobile-margin $mobile-margin;
    }
  }
}

@import "../../styles/base.scss";

.loading-indicator {
  img {
    @include equal-dimensions(24px);
    animation: spin 1.5s infinite linear;
  }
}

.loading-overlay {
    pointer-events: none;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 1000;
    @include equal-dimensions(100%);

    img {
      position: absolute;
      top: $tablet-margin;
      right: $tablet-margin;
    }
}

@media screen and (max-width: $mobile) {
  .loading-overlay {
    img {
      top: $mobile-margin;
      right: $mobile-margin;
    }
  }
}

@keyframes spin {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

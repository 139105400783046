@import "../../styles/base.scss";

.MaintenanceOverlay {
  top: 0;
  left: 0;
  z-index: 1000;
  @include equal-dimensions(100%);
  padding: 46px $desktop-large-margin $desktop-large-margin $desktop-large-margin !important;
  color: $white;

  .header {
    font-family: $font-flama;
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 48px;
    text-transform: uppercase;
  }

  .message {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
    max-width: 644px;
    line-height: 24px;
  }
}

@media screen and (max-width: $tablet-wide) {
  .MaintenanceOverlay {
    padding: 44px $tablet-margin $tablet-margin $tablet-margin !important;

    .header {
      font-size: 33px;
    }

    .message {
      max-width: none;
    }
  }
}

@media screen and (max-width: $mobile) {
  .MaintenanceOverlay {
    padding: 22px $mobile-margin $mobile-margin $mobile-margin !important;

    .header {
      margin-bottom: 22px;
    }
  }
}

@import "../../styles/base.scss";

.search .app-body {
  min-height: 100%;
  padding-bottom: 0;
  .search-page {
    margin: 150px $desktop-large-margin $desktop-large-margin $desktop-large-margin !important;

    &-title {
      font-family: $font-flama;
      font-weight: bold;
      font-size: 52px;
      margin: 46px 0 66px 0;
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .search-page {
    margin: 100px $mobile-margin !important;

    &-title {
      font-size: 33px;
      margin: 40px 0 25px 0;
    }
  }
}

@import "../../styles/base.scss";

.Footer {
  height: auto;
  .back-to-site {
    display: flex;
    align-items: center;
    a {
      color: $white !important;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      height: 17px;
      margin-left: 10px;
    }
  }

  &-quicklinks {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto 1fr;
    color: $white;
    padding: 18px $desktop-large-margin;

    >div, >ul {
      display: flex;
      align-self: center;
      align-items: center;
    }

    li {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px
      }

      a {
        display: flex;
      }
    }

    ul, li {
      display: inline-flex;
      align-items: center;
    }

    >div:first-child, &-social {
      justify-content: flex-start;
    }

    &-apps {
      justify-content: flex-end;
      align-items: center;
      li:first-child {
        height: 17px;
        font-size: 14px;
      }
      .icon {
        object-fit: contain;
        @include equal-dimensions(28px);
      }
  }

  &-social {
    margin-left: 6rem;

    .icon {
      margin-left: 0.5rem;
      @include equal-dimensions(24px);
    }
  }
}

  .divider {
    display: inline-block;
    width: 40px;
    text-align: center;
    font-family: $font-flama-semi;
    font-size: 14px;
    font-weight: bold;
  }

  &-info {
    background: $dark-gray;
    display: grid;
    grid-template-columns: 500px auto;
    grid-template-rows: auto;
    padding: 18px $desktop-large-margin;

    &-wrapper {
      grid-row: 1;
      grid-column: 1;
    }

    li {
      display: inline-block;
      font-family: $font-flama-semi;
      font-size: 14px;
      font-weight: bold;

      a {
        &, &:visited, &:active, &:focus {
          color: $white;
        }
      }
    }

    .compliance-message {
      margin-top: 10px;
      font-family: $font-flama-semi;
      font-size: 12px;
    }
  }

  .store-downloads {
    grid-row: 1;
    grid-column: 2;
    text-align: right;

    img:first-child {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .Footer {

    &-quicklinks, &-info {
      padding: 18px $desktop-small-margin;
    }

    &-quicklinks-social {
      margin-left: 2rem;
    }

    &-info {
      grid-template-columns: 400px auto;
    }

    .store-downloads {
      img {
        width: 130px;
        height: 43px;
      }
      img:first-child {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .Footer {
    &-quicklinks, &-info {
      padding: 18px $desktop-small-margin;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Footer {
    &-quicklinks {
      padding: 0;
      grid-row-start: 3;
      z-index: 6;
      grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content);
      grid-template-columns: auto auto;
      font-size: 14px;

      &.menu_closed {
        display: none;
      }

      .back-to-site {
        padding-left: $mobile-margin;
        grid-row: 1;
        grid-column: 1;
      }

      &-social {
        margin: 6px $mobile-margin 6px 0;
        grid-row: 1;
        grid-column: 2;
        justify-content: flex-end;
      }

      &-apps {
        padding: 15px 0;
        grid-row: 2;
        grid-column: 1/3;
        justify-content: center;
        display: block;
        text-align: center;

        li:first-child {
          display: block;
          margin-bottom: 4px;
        }
      }
    }

    .divider {
      display: inline-block;
      width: 16px;
    }

    &-info {
      display: block;
      text-align: center;

      ul {
        text-align: center;
      }

      .compliance-message {
        text-align: left;
      }
    }

    .store-downloads {
      grid-row: 2;
      grid-column: 1;
      text-align: center;
      margin-top: 20px;

      img:first-child {
        margin-right: 10px;
      }
    }
  }
}

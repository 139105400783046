@import "../../styles/base.scss";

.ShowPage {
  margin-top: 38px !important;
  .KeyArt {
    margin-bottom: 36px;
  }

  .Carousel {
    margin: 36px $desktop-large-margin;
  }
}

@media screen and (max-width: $tablet-wide) {
  .ShowPage {
    .KeyArt {
      margin-bottom: 12px;
    }

    .Carousel {
      margin: 12px $tablet-margin;
    }
  }
}

@media screen and (max-width: $mobile) {
  .ShowPage {
    .Carousel {
      margin: $mobile-margin;
    }
  }
}

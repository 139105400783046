@import "../../styles/base.scss";

.Tile {
  display: flex;
  flex-direction: column;
  margin: 6px 0 24px 0;
  position: relative;
  overflow: hidden;
  transition: all .2s ease-in-out;

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px 10px 8px 10px;
    font-size: 14px;
    background-color: $background-50;
    user-select: text;
    font-family: $font-flama-semi;

    >div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-header {
      color: $primary-red;
      font-weight: bold;
      cursor: pointer;
    }

    &-subheader, &-duration {
      color: $white;
      display: inline-block;
    }

    &-duration {
      float: right;
    }
  }

  &-image {
    position: relative;
    padding-bottom: calc(9/16 * 100%);
    background-color: black;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    // https://brightcove.atlassian.net/browse/RRT-2099
    // &:after {
    //   content: ' ';
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   bottom: 13px;
    //   left: 10px;
    //   background: url(../../assets/images/icons/play-icon.svg) no-repeat;
    // }
  }

  &-progress {
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.2);

    &-bar {
      height: 100%;
      background-color: $primary-red;
    }
  }

  &-badge {
    z-index: 8;
    position: absolute;
    width:50px;
    height: 50px;

    img {
      object-fit: contain;
      max-height: 100%;
    }
  }
}

.Tile-hover {
  transform: scale(1.14);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
  background-color: $dark-gray;
  z-index: 10;
}

@import "../../styles/base.scss";

#root .activate .activate-page>div>button.activate-page-button {
  background-color: #DC0023;
  border-color: #DC0023;
  &:hover {
    background-color:#DC0023 !important;
  }
}
.activate {
  .activate-page {
    margin-top: 0px;
    a img {
      max-width:208px;
    }
    @media only screen and (max-width: 500px) {
      a img {
        max-width:100%;
      }
    }
  }
  &.app-layout {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }

  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;

  .Header {
    display:none;
  }
  h2 {
    font-family: $font-flama;
    font-size: 52px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 27px;
  }
  &-page {
    .spinner {
      justify-self: end;
      animation-name: ckw;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      transform-origin: 50% 50%;
    }
    button.activate-page-button {
      width: 160px;
      height: 44px;
      border-radius: 3px;
      background-color: #DC0023;
      span {
        font-family: $font-flama-semi;
        font-weight: bold;
        font-size: 21px;
      }
    }
    @keyframes ckw {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
  }
  &-success {
    text-align: center;
  }
  &-error {
    margin-top:1rem;
    font-family: Helvetica, Arial, sans-serif;
    font-size:17px;
    color: $dark-red;
  }
  p {
    font-family: Arial, Helvetica, sans-serif;
  }
  .app-body {
    min-height: 434px;
    padding-bottom:0px;
    .activate-page {
      display: grid;
      grid-template-rows: auto;
      min-height: auto;
      padding: $desktop-large-margin;
      background-color: black;
      background-position: left;
      background-repeat: no-repeat;
      background-image: image-set("../../assets/images/live/default-live-asset.jpg" 1x, "../../assets/images/live/default-live-asset.jpg" 2x,
      "../../assets/images/live/default-live-asset.jpg" 3x);
      background-size: cover;
      background-repeat: repeat-y;
    }
    .activate-page.activate-page-success {
      grid-template-rows: auto;
    }
    @supports (-ms-ime-align:auto) {
        &-page {
            background-image: url("../../assets/images/live/default-live-asset.jpg");
        }
    }
    .activate-input {
      @extend %input-bar-wrapper;
      max-width: 445px;
      min-width: 258px;
      width: 100%;
      &-field {
        @extend %input-bar-field;
        input {
          @extend %input-bar-input;
          text-transform: uppercase;
          color: white !important;
          &:focus {
            outline: none;
            color: white !important;
          }
        }
      }
    }
  }
  .Footer {
    display:none;
  }
}

@media screen and (max-width: $mobile-small) {
  .activate {
    grid-template-rows: auto 1fr;

    .app-body {
      background-color: black;
      .activate-page {
        padding: 16px;
        grid-template-rows: auto 1fr;
        background-image: none;
        h2 {
          font-size: 33px;
          margin-top: 44px;
          margin-bottom: 20px;
        }
        p {
          margin-top: 0px;
          margin-bottom: 0px;
        }
        button {
          width: 328px;
        }
      }
    }
  }
}

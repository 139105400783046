@import "../../styles/base.scss";

.ServiceMessages {
  width: 100%;
  position: relative;
  background-color: $primary-red;

  .ServiceMessage {
    position: relative;
    width: 100%;
    padding: 10px 72px 10px 24px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 24px;
    border-bottom: 1px solid $black;

    .close {
      position: absolute;
      top: 10px;
      right: 24px;
      height: 18px;
      cursor: pointer;
    }
  }
}

@import "../../styles/base.scss";

.Filter {
  padding: 36px;
  text-align: center;

  ul {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  &-item {
    display: inline-block;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $font-flama-semi;
    font-weight: bold;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.selected {
      color: $primary-red;
      border-bottom: 2px solid $primary-red;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Filter {
    padding: 30px 16px;
    text-align: left;
  }
}

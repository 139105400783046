@import "./fonts.scss";

// Colors
$red: #eb503a;
$dark-red: #cc2227;
$dark-red-2: #330306;
$primary-red: #dc0023;
$white: #f1f1f1;
$blue: #dc0023;
$dark-blue: #1b232f;
$dark-blue-50: rgba(#1b232f, 0.5);
$dark-blue2: #222f42;
$dark-blue3-28: rgba(#96A7B5, 0.28);
$dark-blue3-50: rgba(#96A7B5, 0.50);
$teal: #02B7C3;
$black: #1e1e1e;
$dark-yellow: #43413c;
$clear: rgba(0,0,0,0);

$screen-slide: #cc2227;
$preview-slide: #b5ad98;
$episode-slide: #2d1f3e;
$slide-label-text: #11263e;

$yellow-top: #fef009;
$yellow-bottom: #f79b00;
$yellow-gradient: linear-gradient($yellow-top, $yellow-bottom);

$background: black;
$background-28: rgba(0,0,0,0.28);
$background-50: rgba(0,0,0,0.5);
$dark-gray: #1d1d1d;

// Media Sizes
$desktop: 1920px;
$tablet-wide: 1024px;
$small: 900px;
$tablet: 768px;
$mobile: 600px;
$mobile-small: 360px;


// Constants
$desktop-large-margin: 76px;
$desktop-small-margin: 36px;
$tablet-margin: 24px;
$mobile-margin: 16px;


// Fonts
$font-flama: 'Flama', Helvetica, Arial, sans-serif;
$font-flama-semi: 'Flama-SC', Helvetica, Arial, sans-serif;
$font-sans-serif: Helvetica, Arial, sans-serif;

// Mixins
@mixin all-heights($value) {
  min-height: $value;
  height: $value;
  max-height: $value;
}

@mixin all-widths($value) {
  min-width: $value;
  width: $value;
  max-width: $value;
}

@mixin equal-dimensions($value) {
  height: $value;
  width: $value;
}


// Global styles

div:focus, button:focus {
  outline: none !important;
}

html, body, #root {
  border: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $background;
  font-size: 17px;
  font-family: $font-flama;
  color: $white;
}

ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-layout {
  position: relative;
  display: grid;
  min-height: 100vh;
  max-width: $desktop;
  margin:auto;
  grid-template-rows: auto;
  grid-template-columns: 100%;
}

.app-body {
  position: relative;
  padding-bottom: 30px;
  min-height: 100vh;
  >div {
    height: 100%;
    margin-top: 90px;
  }
}

.ellipsis-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: $tablet-wide) {
  .app-body {
    >div {
      // min-height: 700px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .app-layout {

    .app-body {
      margin-top: 0;
      > div {
        padding-top: 0;
        // min-height: 600px;
      }
    }
  }
}

%standard-select {
  text-align: right;

  .span {
    text-transform: uppercase;
  }
  .select {
    border-radius: 2px;
    border-color: $dark-blue3-50;
    background-color: $clear;
    height: 36px !important;
    font-family: $font-sans-serif;

    select {
      font-family: $font-flama-semi;
      font-weight: bold;
      color: $primary-red !important;
    }
    &::after {
      color: $primary-red !important;
      font-size: 1rem;
    }
  }

  span:first-child {
    font-family: $font-flama-semi;
    font-size: 14px;
    margin-right: 10px;
    text-transform: uppercase;
  }
}

a {
  text-decoration: none;
}

%input-bar-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $primary-red;
  margin-bottom: 20px;
}
%input-bar-field {
  display: flex;
  margin-bottom: 0;
  width: 100%;
}
%input-bar-input {
  font-size: 48px;
  color: $white;
  background-color: $clear;
  border-width: 0;
  font-family: $font-sans-serif;
}

.hidden {
  display: none !important;
}

@import "../../styles/base.scss";

$aspectRatio: 25/72;

$playerWidthRatio: 760/1440;
$playerWidthRatioTabletWide: 600/1024;
$playerAspectRatio: 9/16;

.VideoMessageOverlay {
  display: flex;
  align-items: center;
  width: 100%;
  // height: calc(#{$playerWidthRatio*$playerAspectRatio}*100vw);
  // height: calc(#{$playerWidthRatio}*100vw);
  // min-height: calc(100vw * #{$aspectRatio});
  position: relative;
  justify-content: center;
  position: absolute;
  z-index: 1;

  &-poster {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(#{$playerWidthRatio*$playerAspectRatio}*100vw);
    position: relative;
    justify-content: center;
    background-color: $background;
    opacity: .66;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;

    button {
      background: $primary-red !important;
      border-color: $primary-red !important;

      &:hover {
        background: $primary-red !important;
        border-color: $primary-red !important;
      }
    }
  }

  &.fadeOut {
    animation: fadeOut 0.666s ease-out forwards;
    display: none;
  }

  &-message {
    text-align: center;
    width: 80%;
    padding: 20px 40px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    border-top: $primary-red 10px solid;
    border-bottom: $primary-red 10px solid;

    a, a:focus, a:visited {
      color: $primary-red;
    }
  }
}

@media screen and (max-width: $tablet-wide) {

  .VideoMessageOverlay-poster {
    height: calc(#{$playerWidthRatioTabletWide*$playerAspectRatio}*100vw);
  }
}

@media screen and (max-width: $tablet) {

  .VideoMessageOverlay-poster {
    height: calc(#{$playerAspectRatio}*100vw);
  }
}

.LiveStreamPage {
  .VideoMessageOverlay {
    height: calc(#{$playerWidthRatio*$playerAspectRatio}*100vw);
  }
}

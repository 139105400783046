@import "../../styles/base.scss";

.search {
  &-input {
    margin-bottom: 40px;
  }

  &-bar {
    @extend %input-bar-wrapper;

    &-icon {
      display: inline-block;
      @include equal-dimensions(48px);
      margin-right: 25px;
      path {
        fill: $primary-red;
      }
    }
    &-field {
      @extend %input-bar-field;
      input {
        @extend %input-bar-input;
        outline: none;
      }
    }
  }

  &-text {
    font-size: 17px;
    font-family: Helvetica, Arial, sans-serif;
    color: $white;

    &.error {
      font-weight: bold;
      color: $primary-red;
    }
  }
}

@media screen and (max-width: $mobile) {
  .search-bar {
    &-icon {
      @include equal-dimensions(32px);
    }

    &-field {
      input {
        font-size: 21px;
      }
    }
  }
}

@import "../../styles/base.scss";

.Schedule {
  margin: 90px auto 50px;
  &-upper-section {
    border-bottom: 2px solid $primary-red;
    z-index: 50;
    background-color: $background;
    top: 0;

    &.fixed {
      position: sticky;
    }
  }

  &-title-bar {
    padding: 46px 290px 0 290px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 61px;
  }

  &-header {
    display: inline-block;
    font-family: $font-flama;
    font-weight: bold;
    font-size: 52px;
    line-height: 42px;
    text-transform: uppercase;
  }

  &-timezone {
    @extend %standard-select;
    display: inline-block;
  }

  &-timeline {
    overflow-x: auto;
    padding: 0 $desktop-large-margin;
    display: flex;
    justify-content: space-between;
    position: relative;

    &-day {
      display: inline-block;
      padding: 9px;
      text-align: center;
      width: 90px;
      cursor: pointer;

      span:first-child {
        display: block;
        font-family: $font-flama-semi;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
      }

      span:last-child {
        font-family: $font-flama-semi;
        font-size: 12px;
      }

      &.same-day {
        border-bottom: 3px solid $primary-red;

        span:first-child {
          color: $primary-red;
        }
      }
    }

    &-arrow {
      position: absolute;
      bottom: 19px;
      cursor: pointer;
      height: 24px;
      width: 24px;

      &.disabled {
        color: $background-28;
        cursor: default;
        pointer-events: none;
      }

      &.left {
        left: 15px;
      }
      &.right {
        right: 15px;
      }
    }
  }
}

.ScheduleEntry {
  background-color: $background;
  display: flex;
  border-top: 1px solid $dark-yellow;
  border-bottom: 1px solid $dark-yellow;
  padding: 20px 290px 20px 0;

  &.now-playing {
    background-color: $dark-gray;
  }

  > div {
    display: inline-block;
  }

  &-time {
    @include all-widths(290px);
    padding-left: $desktop-large-margin;

    >div {
      display: inline-block;
    }

    .now-playing-icon, .now-playing-placeholder {
      display: inline-block;
      width: 37px;
      margin-right: 10px;
      padding-top: 5px;
    }

    .now, .now-mobile {
      font-family: $font-flama-semi;
      font-weight: bold;
      font-size: 17px;
      color: $white;
    }

    .now-mobile {
      display: none;
    }

    .time {
      font-family: $font-flama-semi;
      font-size: 17px;
      color: $white;
    }
  }

  &-show, &-episode, &-info, &-description {
    color: $white;
    font-family: $font-flama-semi;
  }

  &-show {
    font-size: 21px;
    font-weight: bold;
  }

  &-episode {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &-info {
    font-size: 12px;
    margin-bottom: 8px;
  }

  &-watch-now {
    font-size: 14px;
    font-weight: bold;
    color: $primary-red;
    text-transform: uppercase;
    margin-bottom: 8px;
    cursor: pointer;

    a {
      &, &:visited, &:active {
        color: $primary-red;
      }
    }

    img {
      margin-right: 3px;
    }
  }

  &-description {
    font-size: 17px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 8px;
    overflow: hidden;
    max-height: 60px;
    position: relative;
  }

  .expanded &-description {
    max-height: none;
  }

  &-toggle {
    color: $primary-red;
    font-family: $font-flama-semi;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
}

@media screen and (max-width: $tablet-wide) {
  .Schedule {
    &-title-bar {
      padding: 46px 26px 0 190px;
    }

    &-header {
      font-size: 32px;
    }

    &-timeline {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      padding: 0;
    }
  }

  .ScheduleEntry {
    padding: 20px $tablet-margin 20px 0;

    &-time {
      @include all-widths(190px);
      padding-left: $mobile-margin;
    }
  }
}

@media screen and (max-width: $mobile) {
  .Schedule {
    &-title-bar {
      padding: 22px $mobile-margin 0 90px;
      display: block;
      text-align: left;
      margin-bottom: 30px;

      > div {
        display: block;
      }
    }

    &-timeline-day {
      width: 51px;
    }

    &-timezone {
      text-align: left;
      margin-top: 16px;
    }
  }

  .ScheduleEntry {
    padding: 20px $mobile-margin 20px 0;

    &-time {
      @include all-widths(90px);
      padding-left: 16px;
    }

    .now-playing-icon {
      width: 14px;
      margin-right: 4px;
    }

    .now, .now-playing-placeholder {
      display: none;
    }

    .now-mobile {
      display: inline-block;
    }

    &-show {
      font-size: 17px;
    }

    &-episode {
      font-weight: normal;
      font-size: 14px;
    }

    &-description {
      font-size: 14px;
      max-height: 51px;
    }
  }
}

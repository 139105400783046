@import "../../styles/base.scss";

.Grid {
  margin: 0 $desktop-large-margin;
  display: grid;
  grid-template-columns: auto auto;

  &-header {
    font-family: $font-flama-semi;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  //
  // &-sort {
  //   @extend %standard-select;
  // }

  &-contents {
    margin-top: 16px;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    grid-gap: 10px;

    .Tile {
      &-image {
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .Grid {
    margin: 0 $tablet-margin;

    &-contents {
      margin-top: 10px;
      grid-template-columns: repeat(auto-fill, minmax(233px, 1fr));
    }
  }
}


@media screen and (max-width: $mobile) {
  .Grid {
    margin: 0 $mobile-margin;

    &-header {
      grid-column: 1/3;
    }

    .Sort {
      grid-column: 1/3;
      text-align: left;
      margin-top: 10px;
    }

    &-contents {
      grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    }
  }
}

@import "../../styles/base.scss";

$windowAspectRatio: 16/9;
$playerWidthRatio: 760/1440;
$playerWidthRatioTabletWide: 600/1024;
$playerAspectRatio: 9/16;

.app-layout.live {
  grid-template-rows: auto auto;
  background-color: $background;

  .app-body {
    background-color: $background;
  }

  .Header,
  .Footer {
    display: none;
  }

  .LiveStreamPage {
    height: 100%;
    text-align: center;
    background-color: $background;
    min-height: 0px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 0;

    .playerWrapper {
      position: relative;
      background-color: $background;
      background-size: "contain";
      background-repeat: "no-repeat";
      background-position: "center";
      height: calc(#{$playerAspectRatio}*100vw);
      min-height: 100%;
      width: 100%;

      .VideoMessageOverlay {
        height: calc(#{$playerAspectRatio}*100vw);
        z-index: 0;

        &-poster {
          height: calc(#{$playerAspectRatio}*100vw);
        }
      }

      #liveStreamPlayer {
        width: 100%;
        height: 100%;
        > div {
          width: 100%;
          height: 100%;
          .video-js {
            width: 100%;
            height: 100%;

            .vjs-control-bar {
              height: 64px;
              background-color: rgba($background, 0.85);
            }

            .vjs-live-display {
              display: flex;
              flex: 1 1 0;
              align-items: center;
              height: 100%;
              font-size: 12px;
              font-weight: bold;
            }

            .vjs-play-progress, .vjs-volume-level {
              background-color: $primary-red;
            }

            .vjs-button > .vjs-icon-placeholder:before {
              line-height: 3.67 !important;
            }

            .vjs-big-play-button {
              background: url(../../assets/images/icons/play-icon-xxlg.svg) !important;
              display: none!important;

              .vjs-icon-placeholder:before {
                content: "";
              }
            }

            .vjs-dock-shelf, .vjs-dock-text {
              transition: none;
            }

            &.vjs-has-started:not(:hover) .vjs-dock-shelf, .vjs-has-started:not(:hover) .vjs-dock-text {
              opacity: 1;
              transition: none;
            }

            &.vjs-playing .vjs-dock-shelf, &.vjs-playing .vjs-dock-text {
              opacity: 0;
              transition: opacity .25s 3s;
            }

            &.vjs-paused .vjs-dock-shelf, &.vjs-paused .vjs-dock-text {
              opacity: 1;
            }

            .vjs-play-control {
              .vjs-icon-placeholder:before, .vjs-icon-play:before {
                content: "";
                background: url(../../assets/images/icons/play.svg) no-repeat 11px 20px;
              }

              &.vjs-ended .vjs-icon-placeholder:before, .vjs-icon-replay:before {
                content: "";
              }

              &.vjs-playing .vjs-icon-placeholder:before, .vjs-icon-pause:before {
                content: "";
                background: url(../../assets/images/icons/pause.svg) no-repeat 11px 20px;
              }
            }

            .vjs-mute-control {
              .vjs-icon-placeholder:before, .vjs-icon-volume-high:before {
                content: "";
                background: url(../../assets/images/icons/volume-btn.svg) no-repeat 11px 20px;
              }

              &.vjs-vol-2 .vjs-icon-placeholder:before, .vjs-icon-volume-mid:before {
                content: "";
                background: url(../../assets/images/icons/volume-btn-med.svg) no-repeat 11px 20px;
              }

              &.vjs-vol-1 .vjs-icon-placeholder:before, .vjs-icon-volume-low:before {
                content: "";
                background: url(../../assets/images/icons/volume-btn-low.svg) no-repeat 11px 20px;
              }

              &.vjs-vol-0 .vjs-icon-placeholder:before, .vjs-icon-volume-mute:before {
                content: "";
                background: url(../../assets/images/icons/volume-btn-muted.svg) no-repeat 11px 20px;
              }
            }

            .vjs-volume-control {
              &.vjs-volume-vertical {
                width: 24px !important;
                height: 122px !important;
                bottom: 118px !important;
                left: -37px !important;
                background-color: #1e1e1e;
              }

              .vjs-volume-triangle {
                position: absolute;
                bottom: -11.4px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 11px solid #1e1e1e;
              }

              .vjs-volume-handle {
                  width: 32px;
                  height: 32px;
                  left: -14px;
                  top: -18px;
                  position: absolute;
                  display: block;
                  background: url(../../assets/images/icons/handle.svg) no-repeat;
              }
            }

            .vjs-volume-bar {
              &.vjs-slider-vertical {
                width: 4px;
                height: 101px;
                border-radius: 1px;
                background-color: rgba(255, 255, 255, 0.3);
              }
            }


            .vjs-subs-caps-button {
              cursor: pointer;
              .vjs-icon-placeholder:before {
                background: url(../../assets/images/icons/cc.svg) no-repeat 11px 20px;
                content: "";
              }
              &.vjs-cc-on .vjs-icon-placeholder:before {
                background: url(../../assets/images/icons/cc-on.svg) no-repeat 11px 20px;
                content: "";
              }
              .vjs-menu {
                display: none !important;
              }
            }

            .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-enter:before {
              content: "";
              background: url(../../assets/images/icons/maximize.svg) no-repeat 11px 20px;
            }

            .vjs-play-progress .vjs-progress-handle {
                width: 32px;
                height: 32px;
                position: absolute;
                top: -0.9rem;
                display: block;
                background: url(../../assets/images/icons/handle.svg) no-repeat;
            }

            .vjs-current-time-display, .vjs-duration-display {
              font-family: $font-flama-semi;
              font-size: 12px;
              color: #f1f1f1;
              line-height: 5.5 !important;
            }

            .vjs-progress-control {
              position: relative;
              left: auto;
              right: auto;
              width: 368px;
            }

            &.not-hover.vjs-has-started .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
              transform: translateY(6em) !important;
            }

            .vjs-progress-control {
              display: none;
              height: 4px !important;
              top: 2.5em !important;
            }

            &.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-exit:before {
              content: "";
              background: url(../../assets/images/icons/minimize.svg) no-repeat 11px 20px;
            }

            .vjs-picture-in-picture-control {
              display: none;
            }

            .vjs-loading-spinner {
              border: none;
            }

            &.vjs-seeking .vjs-loading-spinner:after, &.vjs-seeking .vjs-loading-spinner:before, &.vjs-waiting .vjs-loading-spinner:after, &.vjs-waiting .vjs-loading-spinner:before {
              background: url(../../assets/images/icons/spinner.svg) no-repeat;
              background-size: cover;
              -webkit-animation: spin 1s linear infinite;
              animation: spin 1s linear infinite;
            }

            &.vjs-seeking .vjs-loading-spinner:after, &.vjs-waiting .vjs-loading-spinner:after {
              border-top-color: transparent;
              -webkit-animation-delay: 0s;
              animation-delay: 0s;
            }
          }
        }
      }
    }

    .live-player-header {
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }

    #anvatoPlayer.mobile {
      height: calc(#{$windowAspectRatio}*100vw);
      width: 100%;
    }
  }

  @keyframes fadeOut {
    0% {
      display: flex;
      opacity: 1;
    }

    1% {
      display: flex;
      opacity: 1;
    }

    100% {
      opacity: 0;
      display: none !important;
    }
  }
}

@import "../../styles/base.scss";

$aspectRatio: 6/16;
$mobileAspectRatio: 19/18;
$smallMobileAspectRatio: 430/360;
$mobileImageAspectRatio: 9/16;
$mobileMetadataAspectRatio: 23/20;

.LiveSlide {
  min-height: calc(100vw * #{$aspectRatio});
  position: relative;
  display: flex;
  justify-content: center;

  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-position: center;
  }

  .watch-image {
    position: absolute;
    top: 30%;
    height: auto;
  }

  .metadata {
    z-index: 1;
    align-self: flex-end;
  }
}

@media screen and (max-width: $tablet) {
  .LiveSlide {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: calc(#{$mobileImageAspectRatio}*100vw) calc(#{$mobileMetadataAspectRatio}*100vw);
    height: calc(100vw * #{$mobileAspectRatio});
    justify-content: normal;

    .image {
      grid-column: 1;
      grid-row: 1;
      position: relative;
    }

    .watch-image {
      position: initial;
      max-height: none;
      max-width: 78%;
      margin: auto;
    }

    .metadata {
      grid-column: 1;
      grid-row: 2;
      border-width: $mobile-margin;
      align-self: auto;
      background-color: $background;
    }
  }
}

@media screen and (max-width: $mobile-small) {
  .LiveSlide {
    height: calc(100vw * #{$smallMobileAspectRatio});
  }
}

@import "../../styles/base.scss";

.sign-in {
  width: 100%;
  height: 100%;
  background: $background;
  padding: 40px $desktop-large-margin !important;

  &-close {
    float: right;
    cursor: pointer;

    svg {
      @include equal-dimensions(24px);
    }
  }

  &-header {
    font-size: 52px;
    margin-bottom: 12px;
    font-weight: 600;
  }

  &-body {
    font-size: 17px;
    font-family: Helvetica, Arial, sans-serif;
  }

  &-providers {
    margin: 36px 0;
    display: grid;
    grid-gap: 13px;
    grid-template-columns: repeat(auto-fill, minmax(201px, max-content));
  }

  &-all-providers {
    max-width: 222px;

    >div {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 7px;
    }

    span {
      border-radius: 2px;

      &::after {
        color: $primary-red;
        font-size: 2rem;
      }

      select {
        font-size: 17px;
        font-weight: 600;
        color: $primary-red;
      }
    }
  }

  &-missing {
    font-weight: 400;
    font-size: 12px;
    margin-top: 36px;
  }

  .provider-icon {
    background-color: $white;
    width: 201px;
    height: 113px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    cursor: pointer;

    img {
      max-height: 33px;
    }
  }
}

@media screen and (max-width: $tablet-wide) {
  .sign-in {
    padding: $tablet-margin !important;

    &-close {
      margin-right: 8px;
    }

    &-header {
      font-size: 33px;
      margin-bottom: 6px;
    }

    &-providers {
      margin: 22px 0;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(154px, max-content));
    }

    &-missing {
      margin-top: 22px;
    }

    .provider-icon {
      width: 154px;
      height: 87px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .sign-in {
    padding-bottom: 177px !important;

    &-providers {
      margin: 22px 0;
      grid-template-columns: repeat(auto-fill, minmax(172px, max-content));
    }

    .provider-icon {
      width: 172px;
      height: 97px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .sign-in {
    padding: $mobile-margin !important;

    &-all-providers {
      max-width: 100%;
    }

    &-providers {
      margin: 22px 0;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(159px, max-content));
    }

    .provider-icon {
      width: 159px;
      height: 89px;
    }
  }
}
